
.square-dark{
    background-color: #000000ce!important;
    z-index: 999999;
    padding: 20px;
}

.square-dark .head, .square-dark .headText { color:white;background-color: transparent!important;}
.square-dark-bold{
    background-color: #000000ce!important;
    z-index: 999999;
    padding: 20px;
    
}
.square-dark-bold .head {font-weight: 600; color:white;background-color: transparent!important;}
.square-dark-bold .headText {color:white;background-color: transparent!important;}

.border-dark-bold{
    border:solid rgb(255, 255, 255) 5px;
    background-color: transparent!important;
    z-index: 999999;
    padding: 20px;
    
}
.border-dark-bold .head {font-weight: 600; color:white;background-color: transparent!important;}
.border-dark-bold .headText {color:white;background-color: transparent!important;}

.border-dark{
    border:solid rgb(255, 255, 255) 2px;
    background-color: transparent!important;
    z-index: 999999;
    padding: 20px;
    
}
.border-dark .head {color:white;background-color: transparent!important;}
.border-dark .headText {color:white;background-color: transparent!important;}


.square-light{
    background-color: #ffffff9c!important;
    z-index: 999999;
    padding: 20px;
    color:black;
}
.square-light .head, .square-light .headText {color:black;background-color:  transparent!important;}

.square-light-bold{
    background-color: #ffffff9c!important;
    z-index: 999999;
    padding: 20px;
    color:black;
    font-weight: 600;
}
.square-light-bold .headText {color:black;background-color:  transparent!important;}
.square-light-bold .head {font-weight: 600;color:black;background-color:  transparent!important;}

.border-light-bold{
    border:solid black 5px;
    background-color: transparent!important;
    z-index: 999999;
    padding: 20px;
    color:black;
    font-weight: 600;
}
.border-light-bold .headText {color:black;background-color:  transparent!important;}
.border-light-bold .head {font-weight: 600;color:black;background-color:  transparent!important;}

.border-light{
    border:solid black 2px;
    background-color: transparent!important;
    z-index: 999999;
    padding: 20px;
    color:black;
    
}
.border-light .headText {color:black;background-color:  transparent!important;}
.border-light .head {color:black;background-color:  transparent!important;}


.bold{
    font-weight: 600;
}

.text-light .head {
    color: #fdfdfe !important;
    text-shadow: none !important;
}

.text-light .headText {
    color: #fdfdfe !important;
    background-color: transparent !important;
}

.text-light-neon .head {
    color: #fdfdfe !important;
    text-shadow: 0 0 5px #b393d3, 0 0 10px #b393d3, 0 0 10px #b393d3, 0 0 20px #b393d3 !important;
}

.text-light-neon .headText {
    text-shadow: 0 0 5px #b393d3, 0 0 10px #b393d3, 0 0 10px #b393d3, 0 0 20px #b393d3 !important;
    color: #fdfdfe !important;
    background-color: transparent !important;
}

.text-light-bg .head {
    color: #fdfdfe !important;
    text-shadow: 0 0 5px #b393d3, 0 0 10px #b393d3, 0 0 10px #b393d3, 0 0 20px #b393d3 !important;
}

.text-light-bg .headText {
    text-shadow: none!important;
    color: #fdfdfe !important;
    background-color: #fff6 !important;
}



.text-dark .head {
    text-shadow: none !important;
    color: #fdfdfe !important;
    text-shadow: none !important;
}

.text-dark .headText {
    color: #1a1a1a !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-weight: 100!important;
}


.text-dark-bg .head {
    text-shadow: none !important;
    color: #000000 !important;
}

.text-dark-bg .headText {
    
    color: #1a1a1a !important;
    background-color: #fff6 !important;
}


.center {
    flex-direction: column;
    align-items: center;   
}

.center-top {
    align-items: center!important;
    justify-content: flex-start!important;
}
.center-bottom {
    align-items: center!important;
    justify-content: end!important;
}
.left-top {
    align-items: flex-start!important;
    justify-content: left!important;
}
.left-center {
    align-items: flex-start!important;
    justify-content: center!important;
}
.left-bottom {
    align-items: flex-start!important;
    justify-content: end!important;
}
.right-top {
    align-items: end!important;
    justify-content: right!important;
}
.right-center {
    align-items: end!important;
    justify-content: center!important;
}
.right-bottom {
    align-items: end!important;
    justify-content: end!important;
}
.headTextContainer{
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px; /* Predvolený padding */
    margin-top:50px!important;
        margin-bottom:50px!important
  }
  
  @media (min-width: 700px) {
    .headTextContainer {
        margin-top:60px!important;
        margin-bottom:60px!important;
        margin: 40px; /* Väčší padding pre obrazovky s minimálnou šírkou 600px */
    }
  }
  
  @media (min-width: 1200px) {
    .headTextContainer {
        margin: 60px; /* Ešte väčší padding pre obrazovky s minimálnou šírkou 1200px */
    }
  }
